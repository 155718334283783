<template>
  <el-card class="v_user_cash_account_list">
    <div class="g-flex"  slot="header">
      <span>用户提现账户列表</span>
      <div class="g-flex-justify-end g-flex-1">
        <!-- <el-button size="mini" type="success" @click="setForm({}, 1)">添加银行</el-button> -->
      </div>
    </div>
    <!-- 搜索 -->
    <el-form :inline="true" size="mini" :model="query">
       <el-form-item label="账号类型">
          <el-select @change="getList()" v-model="query.type" placeholder="查询">
            <el-option label="全部" value="ALL"></el-option>
            <el-option label="银行卡" value="1"></el-option>
            <el-option label="支付宝" value="2"></el-option>
          </el-select>
       </el-form-item>
        <el-form-item>
          <el-select slot="label" v-model="query.search_key" placeholder="查询">
            <el-option label="用户ID" value="user_id"></el-option>
          </el-select>
          <el-row>
            <el-col :span="14">
              <el-input v-model="query.search_val" @keyup.enter.native="getList()" @clear="getList()" placeholder="请输入查找内容" clearable></el-input>
            </el-col>
            <el-col :span="4" :offset="1">
              <el-button type="info" @click="resetData">重置</el-button>
            </el-col>
            <el-col :span="4" :offset="1">
              <el-button type="primary" @click="getList()">查询</el-button>
            </el-col>
          </el-row>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <el-table size="mini" :row-class-name="tableRowClassName" v-loading="table.loading" :data="table.rows" border>
      <el-table-column label="id">
          <template slot-scope="scope">
              <span>{{scope.row.id}}</span>
          </template>
      </el-table-column>
      <el-table-column label="用户id">
          <template slot-scope="scope">
              <span>{{ scope.row.user_id }}</span>
          </template>
      </el-table-column>
      <el-table-column label="账号类型" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span class="g-red" v-if="scope.row.type == 1">银行卡</span>
            <span class="g-green" v-if="scope.row.type == 2">支付宝</span>
          </template>
      </el-table-column>
      <el-table-column label="银行名称" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ scope.row.bank_name }}</span>
          </template>
      </el-table-column>
      <el-table-column label="账号" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ scope.row.card_number }}</span>
          </template>
      </el-table-column>
      <!-- <el-table-column label="状态" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span class="g-red" v-if="scope.row.status == 0">禁用</span>
            <span class="g-green" v-if="scope.row.status == 1">正常</span>
          </template>
      </el-table-column> -->
      <el-table-column label="创建时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ formatDate(scope.row.create_time,'yyyy-MM-dd hh:mm:ss') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" :show-overflow-tooltip="true">
        <template slot-scope="scope">
            <span>{{ formatDate(scope.row.modify_time,'yyyy-MM-dd hh:mm:ss') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="setForm(scope.row, 2)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
    
    <user-cash-account-list-pop ref="UserCashAccountListPop" @emitSaveSuccess="getList(false)"/>

  </el-card>
</template>

<script>
  import { formatDate } from '@/utils/index.js';
  import { mixins } from '@/utils/mixins.js'
  import { apiGetUserBankList } from '@/utils/api.js'
  import UserCashAccountListPop from './UserCashAccountListPop.vue'
  export default {
    name: 'userCashAccountList',
    mixins: [mixins],
    components: { UserCashAccountListPop },
    data() {
      return {
        auth:{
          addBlack: this.$global.ApiList.includes(60),
          delBlack: this.$global.ApiList.includes(61),
        },
        platFormList: [],

        query: { search_key: 'user_id', search_val: '', type: 'ALL', page:1, limit: 15 },
        table:{ loading:false, total:0, rows:[] },
        formData: {},
      }
    },
    async created() {
      this.getList()
    },
    filters: {
        filtersSex(sex) {
          if(sex == 1) return '男'
          return '女'
        },
        filtersPlatName(id, list) {
        let obj = list.find(item => item.id == id)
        if(obj) return obj.title
        return ''
      },
    },
    methods: {
      formatDate,
      // cellMouseEnter(row, column, cell, event) {
      //   console.log(row)
      //   row.className = 'hover-rowssssss'
      //   console.log(column)
      //   console.log(cell)
      //   console.log(event)
      // },
      // cellMouseLeave(row, column, cell, event) {
      //   console.log(row)
      //   row.className = ''
      //   console.log(column)
      //   console.log(cell)
      //   console.log(event)
      // },

      async getList(pageInit = true) {
        if (pageInit) this.query.page = 1
        this.table.loading = true
        const { success, data } = await apiGetUserBankList(this.query).catch(() => this.table.loading = false)
        if(!success) return
        this.table.loading = false
        this.table.rows = data.rows
        this.table.total = data.total
      },

      //每页数量改变
      sizeChange(limit) {
        this.query.limit = limit
        this.getList()
      },

      //设置form
      setForm(formData = { id: ''  }, status = 1) {
        if(!formData.id) return this.$refs.UserCashAccountListPop.onShow({ id: '' }, status)
        this.formData = JSON.parse(JSON.stringify(formData))
        return this.$refs.UserCashAccountListPop.onShow(this.formData, status)
      },

      // 点击重置
      resetData() {
        this.query = { search_key: 'user_id', search_val: '', type: 'ALL', page:1, limit: 15 },
        this.getList()
      },
      
    }
  }
</script>

<style lang="scss">
// .v_user_cash_account_list {
//   .el-table {
//     .el-table__body tr:hover td {
//       background-color: transparent;
//     }
//     .el-table__fixed-right tr:hover td {
//       background-color: transparent;
//     }
//   } 
// }

</style>