<template>
  <div class="c_user_list_change_money_pop">
    <el-dialog :title="seeStatus == 1 ? '添加账号': '编辑账号'" width="600px" @close="onClose" :close-on-click-modal="false" :visible.sync="show">
        <el-form size="small" :model="form" label-width="150px">
          <el-form-item label="账号ID:" v-if="form.id">
              <span> {{form.id}} </span> 
            </el-form-item>
            <el-form-item label="账号类型:">
               <el-row>
                <el-col :span="18">
                  <span v-if="form.type == 1">银行卡</span>
                  <span v-if="form.type == 2">支付宝</span>
                </el-col>
               </el-row>
            </el-form-item>
            <el-form-item label="姓名:">
               <el-row>
                <el-col :span="18">
                    <el-input placeholder="请输入姓名" v-model="form.name"></el-input>
                </el-col>
               </el-row>
            </el-form-item>
            <el-form-item label="银行名称:">
               <el-row>
                <el-col :span="18">
                    <el-input placeholder="请输入银行名称" v-model="form.bank_name"></el-input>
                </el-col>
               </el-row>
            </el-form-item>
            <el-form-item label="账号:">
               <el-row>
                <el-col :span="18">
                    <el-input placeholder="请输入账号" v-model="form.card_number"></el-input>
                </el-col>
               </el-row>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="onClose">取 消</el-button>
          <el-button type="primary" @click="apiSaveBankHandel">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiSaveBank } from '@/utils/api.js'
export default {
  data() {
    return {
      seeStatus:1,
      show: false,
      options: [
        { title: '禁用', value: 0 },
        { title: '正常', value: 1 }
      ],
      form: {
        id: '',
        bank_name: '',
        card_number: '',
        type: 1,
        black_status: 0
      },
    }
  },
  methods: {
    // 修改买家金额
    async apiSaveBankHandel() {
      console.log(this.form.money)
      if(!this.form.bank_name) return this.$message.error('请填写银行名称')
      let loading = this.$loading()
      const { success, data } = await apiSaveBank(this.form).catch(() => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.onClose()
      this.$emit('emitSaveSuccess')
      console.log(data)
    },

    // status //1添加 2编辑
    onShow(data = { id: '' }, status) {
      if(data.id) {
        this.form = Object.assign({}, this.form, data)
      }
      this.seeStatus = status
      this.show = true
    },

    onClose() {
      this.form.id = ''
      this.form.bank_name = ''
      this.form.status = 1
      this.show = false
    }
  }
}
</script>

<style lang='scss'>
</style>